import React, { FC, useEffect, useState } from "react";
import { Box, Progress } from "@chakra-ui/react";

const Layout: FC = (props) => {
  return (
    <Box
      width="100%"
      maxWidth="600px"
      mx="auto"
      position="relative"
      bg="gray.50"
    >
      {props.children}
    </Box>
  );
};

export default Layout;
