import React from "react";
import { Image, Flex, Text } from "@chakra-ui/react";

const Header = () => {
  return (
    <Flex
      direction="row"
      background="secondary.500"
      align="center"
      justify="space-between"
      p={2}
      zIndex={1050}
    >
      <Image src={`${process.env.PUBLIC_URL}/images/logo.png`} height="50px" />
    </Flex>
  );
};

export default Header;
