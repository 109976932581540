import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

interface IThankYouModal {
  isOpen: boolean;
  onClose: () => void;
}

export default function ThankYouModal({ isOpen, onClose }: IThankYouModal) {
  return (
    <>
      <Modal isOpen={isOpen} isCentered={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader> Thank You ! </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Your response has been submitted, Thank you for completing the
            survey.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
