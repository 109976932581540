import React, { FC, useContext } from "react";
import { Wrapper, Header } from "../components";
import { Box, Image, Flex, Text, Button } from "@chakra-ui/react";
import StateContext from "../context";
import { useHistory } from "react-router-dom";

const StartScreen = () => {
  const context = useContext(StateContext);
  const history = useHistory();

  return (
    <>
      <Header />
      <Wrapper align="center" justify="center">
        <Box width="100%" p={4} textAlign="center">
          <Flex direction="row" align="center" justify="center">
            <Image
              src={`${process.env.PUBLIC_URL}/images/ceo-logo.jpg`}
              height="80px"
            />
          </Flex>

          <Text
            mt={5}
            mb={1}
            fontWeight="600"
            fontSize="lg"
            textAlign="center"
            textTransform="capitalize"
          >
            Dear {context?.user?.name ? context?.user?.name : "Employee"},
          </Text>

          <Text color="gray.400" textAlign="center" fontSize="xs" px={2}>
            Hope you and your family are doing well. Please help us with your
            feedback on these questions.
          </Text>

          <Button
            colorScheme="primary"
            mx="auto"
            mt={6}
            width="80%"
            onClick={() => {
              history.push("/quiz");
            }}
          >
            Start
          </Button>
        </Box>
      </Wrapper>
    </>
  );
};

export default StartScreen;
