import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import LoadingScreen from "./screens/loading-screen";
import StartScreen from "./screens/start-screen";
import QuizScreen from "./screens/quiz-screen";

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true}>
          <LoadingScreen />
        </Route>
        <Route path="/start">
          <StartScreen />
        </Route>
        <Route path="/quiz">
          <QuizScreen />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navigation;
