import React, { useContext } from "react";
import { Flex, Box, Image, Text } from "@chakra-ui/react";
import StateContext from "../context";

const ImageWithName = () => {
  const {
    user: { name },
  } = useContext(StateContext);

  const jumbeldName = () => {
    let nameArray: Array<string> = name.split(" ");

    nameArray = nameArray.map((nm) => {
      let nmArray = nm.split("");

      let firstEl = nmArray[0];
      let thirdEl = nmArray[2];

      nmArray.splice(0, 1, thirdEl);
      nmArray.splice(2, 1, firstEl);

      return nmArray.join("");
    });

    return nameArray.join(" ");
  };

  return (
    <>
      <Flex width="100%" my={2} align="center" justify="center">
        <Box width="100%" maxWidth="328px" minWidth="328px" position="relative">
          <Image
            width="328px"
            height="205.84px"
            src={`${process.env.PUBLIC_URL}/images/award.jpg`}
          />
          <Text
            fontSize="8px"
            position="absolute"
            bottom="60px"
            width="50%"
            textAlign="center"
            letterSpacing="0.5px"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            &nbsp; {jumbeldName()}
          </Text>

          <Text
            fontSize="6px"
            position="absolute"
            left="55%"
            top="52px"
            width="35%"
            textAlign="center"
            letterSpacing="0.5px"
            textTransform="uppercase"
          >
            &nbsp; {jumbeldName()}
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default ImageWithName;
