import React, { FC } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import Styles from "./index.module.css";

const Wrapper: FC<FlexProps> = (props) => {
  return (
    <Flex width="100%" direction="column" className={Styles.wrapper} {...props}>
      {props.children}
    </Flex>
  );
};

export default Wrapper;
