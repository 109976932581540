import { type } from "os";
import React, { useState, createContext, FC, useEffect } from "react";
import { STORAGE } from "./constants";
import { sendAnswer } from "./services";

const StateContext = createContext<{
  isLoadingUser: boolean;
  questions: Array<any>;
  setIsLoadingUser: (value: boolean) => void;
  setQuestions: (value: Array<IQuestionModel>) => void;
  setUser: (value: any) => void;
  user?: any;
  updateQuestionWithAnswer?: (value: IQuestionModel) => void;
  isUplodaing: boolean;
  setIsUplodaing: (value: boolean) => void;
}>({
  isLoadingUser: false,
  questions: [],
  setIsLoadingUser: (value: boolean) => {},
  setQuestions: (value: Array<IQuestionModel>) => {},
  setUser: (value: any) => {},
  user: {},
  isUplodaing: false,
  setIsUplodaing: (value: boolean) => {},
});

const StateContextWrapper: FC = ({ children }) => {
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const [isUplodaing, setIsUplodaing] = useState(false);

  const [user, setUser] = useState<null | { name: string; sapcode: string }>(
    JSON.parse(localStorage.getItem(STORAGE.USER) || "{}")
  );

  const [questions, setQuestions] = useState<Array<IQuestionModel>>(
    JSON.parse(localStorage.getItem(STORAGE.QUESTION) || "[]")
  );

  useEffect(() => {
    localStorage.setItem(STORAGE.USER, JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem(STORAGE.QUESTION, JSON.stringify(questions));
  }, [questions]);

  const updateQuestionWithAnswer = (que: IQuestionModel) => {
    console.log(que);

    // LOGIC TO SAVE QUESTION
    let bodyFormData = new FormData();

    bodyFormData.append("question", que.que);

    if (typeof que.ans === "number") {
      let ans = que.options[que.ans];
      bodyFormData.append("answer", ans);
    } else if (typeof que.ans === "string") {
      if (que.ans) bodyFormData.append("answer", que.ans as unknown as string);
    }

    if (que.cid) {
      bodyFormData.append("cid", que.cid);
    }

    sendAnswer(user?.sapcode ? user.sapcode : "", bodyFormData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    let newQuestions = questions.map((q) => {
      if (q.que === que.que) {
        return que;
      }

      return q;
    });

    setQuestions(newQuestions);
  };

  return (
    <StateContext.Provider
      value={{
        isLoadingUser,
        setIsLoadingUser,
        user,
        setUser,
        questions,
        setQuestions,
        updateQuestionWithAnswer,
        isUplodaing,
        setIsUplodaing,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateContextWrapper };

export default StateContext;

export interface IQuestionModel {
  que: string;
  options: Array<string>;
  cid?: string;
  ans?: number | string;
}
