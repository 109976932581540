import React from "react";

import { ThemeProvider, CSSReset } from "@chakra-ui/react";
import Theme from "./theme";
import { Layout } from "./components";
import Navigation from "./navigation";
import { StateContextWrapper } from "./context";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CSSReset />
      <StateContextWrapper>
        <Layout>
          <Navigation />
        </Layout>
      </StateContextWrapper>
    </ThemeProvider>
  );
}

export default App;
