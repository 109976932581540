import { extendTheme } from "@chakra-ui/react";
// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    secondary: {
      "50": "#FEF5E6",
      "100": "#FDE4B9",
      "200": "#FCD28D",
      "300": "#FBC160",
      "400": "#FAAF33",
      "500": "#f9a61c",
      "600": "#C77E05",
      "700": "#955F04",
      "800": "#633F03",
      "900": "#322001",
    },
    primary: {
      "50": "#EBEFFA",
      "100": "#C7D2F0",
      "200": "#A3B5E6",
      "300": "#7F98DC",
      "400": "#5A7CD2",
      "500": "#365FC9",
      "600": "#2B4CA1",
      "700": "#213978",
      "800": "#162650",
      "900": "#0B1328",
    },
  },
  fonts: {
    heading: "Manrope",
    body: "Manrope",
  },
});

export default theme;
