import { Flex, Button } from "@chakra-ui/react";

const Footer = ({
  onClick,
  label,
  isSubmitting,
}: {
  onClick: () => void;
  label?: string;
  isSubmitting?: boolean;
}) => {
  return (
    <Flex
      onClick={onClick}
      position="absolute"
      bottom="0px"
      left="0"
      bg="white"
      p={4}
      width="100%"
      zIndex={1050}
    >
      <Button colorScheme="secondary" ml="auto" isLoading={isSubmitting}>
        {label ? label : "Next"}
      </Button>
    </Flex>
  );
};

export default Footer;
