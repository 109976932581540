import React, { FC, useEffect, useState, useContext } from "react";
import { Box, Progress, Image } from "@chakra-ui/react";
import { Wrapper } from "../components";
import StateContext from "../context";
import { getUser, getQuestions } from "../services";
import { useHistory } from "react-router-dom";

const LoadingScreen = () => {
  const context = useContext(StateContext);

  const [percent, setPercent] = useState(0);

  const history = useHistory();

  useEffect(() => {
    fetchQuesAndUsers();

    let percentInterval = setInterval(() => {
      if (percent > 75) {
        clearInterval(percentInterval);
      } else {
        setPercent((percent) => percent + 1);
      }
    }, 100);

    return () => {
      if (percentInterval) {
        clearInterval(percentInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (percent >= 100 && context.user && context.questions.length) {
      history.push("/start");
    }
  }, [percent]);

  const fetchQuesAndUsers = async () => {
    const SAPCODE = new URL(window.location.href).searchParams.get("sapcode");

    console.log(SAPCODE);

    if (!SAPCODE) {
      return null;
    }

    if (
      context.user &&
      context.user.sapcode === SAPCODE &&
      context.questions.length
    ) {
      setPercent(100);
      return;
    }

    try {
      let userBody = await getUser(SAPCODE);

      // FETCH QUESTIONS
      //@ts-ignore
      if (userBody && userBody.data?.user) {
        //@ts-ignore
        let user = userBody.data?.user;
        context.setUser(user);
      } else {
        throw new Error("User not found.");
      }

      let questionsBody = await getQuestions(SAPCODE);
      //@ts-ignore
      if (questionsBody && questionsBody.data?.qa) {
        //@ts-ignore
        let qstns = questionsBody.data?.qa;
        context.setQuestions(qstns);
      } else {
        throw new Error("Questions not found.");
      }

      setPercent(100);
    } catch (error) {
      console.log(error);
    }

    return <> </>;
  };

  return (
    <Wrapper
      align="center"
      justify="center"
      bg="secondary.500"
      minH="100vh"
      position="relative"
    >
      <Box position="absolute" top="0" left="0" width="100%">
        <Progress colorScheme="primary" value={percent} height="4px" />
      </Box>

      <Image src={`${process.env.PUBLIC_URL}/images/logo.png`} height="50px" />
    </Wrapper>
  );
};

export default LoadingScreen;
