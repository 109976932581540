import React, { useContext } from "react";
import { IQuestionModel } from "../context";
import { Text, Flex, Box } from "@chakra-ui/react";
import StateContext from "../context";
import ImageWithName from "./ImageWithName";

interface IQuestionOptionBlock extends IQuestionModel {
  index: number;
  onOptionSelected?: (opt: number, qstn: IQuestionModel) => void;
}

const QuestionOptionBlock = ({
  que,
  options,
  ans,
  cid,
  onOptionSelected,
  index,
}: IQuestionOptionBlock) => {
  const context = useContext(StateContext);

  const handleOptionSelected = (indx: number) => {
    if (indx === ans) return;

    if (!onOptionSelected) return;

    onOptionSelected(indx, { que, options, ans: indx, cid });

    if (context.updateQuestionWithAnswer)
      context.updateQuestionWithAnswer({ que, options, ans: indx, cid });
  };

  return (
    <>
      <Text fontSize="md" fontWeight="semibold" textAlign="center" mb={4}>
        {que}
      </Text>

      {index === 2 ? <ImageWithName /> : null}

      {options.map((opt, indx) => (
        <Box mb={3} onClick={() => handleOptionSelected(indx)} key={opt}>
          <OptionBlock opt={opt} isSelected={indx == ans ? true : false} />
        </Box>
      ))}
    </>
  );
};

export default QuestionOptionBlock;

interface IOptionBlock {
  opt: string;
  isSelected?: boolean;
}

const OptionBlock = ({ opt, isSelected }: IOptionBlock) => {
  return (
    <Flex
      direction="row"
      align="center"
      p={3}
      borderWidth="1px"
      borderColor="gray.200"
      minHeight="42px"
      borderRadius="8px"
      bg={isSelected ? "secondary.500" : "white"}
      cursor="pointer"
    >
      {isSelected ? (
        <Flex
          direction="row"
          align="center"
          justify="center"
          height="20px"
          width="20px"
          borderRadius="50%"
          borderWidth="1px"
          borderColor="white"
          mr={3}
          color="white"
        >
          &#10003;
        </Flex>
      ) : (
        <> </>
      )}

      <Text color={isSelected ? "white" : "black"}> {opt} </Text>
    </Flex>
  );
};
