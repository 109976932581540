import axios from "axios";

//const BASE_URL = "http://localhost/econnect/index.php";
//const BASE_URL = "http://survey.iloopworld.co.in/econnect/index.php";
const BASE_URL = "/econnect/index.php";

export const getUser = (SAPCODE: string) => {
  return axios.get(`${BASE_URL}/users/${SAPCODE}`);
};

export const getQuestions = (SAPCODE: string) => {
  return axios.get(`${BASE_URL}/questions-and-answers/${SAPCODE}`);
};

export const sendAnswer = (SAPCODE: string, formData: any) => {
  return axios({
    method: "post",
    url: `${BASE_URL}/answer/${SAPCODE}`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
