import React, { FC, useState, useContext, useRef, useEffect } from "react";
import { Wrapper, Header } from "../components";
import {
  Box,
  Image,
  Flex,
  Text,
  Button,
  Divider,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import Slider from "react-slick";
import StateContext, { IQuestionModel } from "../context";
import QuestionOptionBlock from "../components/question-option";
import Footer from "../components/footer";
import ThankYouModal from "../components/thank-you";

const QuizScreen = () => {
  const [screens] = useState(new Array(3));
  const context = useContext(StateContext);
  const [pos, setPos] = useState(0);
  const swiperRef = useRef<any>(null);
  const [showNext, setShowNext] = useState(false);
  let [feedbackValue, setfeedbackValue] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    //draggable: false
  };

  const slideNext = () => {
    setPos((pos) => pos + 1);

    if (pos > 3) {
      return;
    }

    if (swiperRef && swiperRef?.current && swiperRef?.current?.slickNext) {
      swiperRef.current.slickNext();
    }
  };

  let contarctQuestions: Array<IQuestionModel> = context.questions.filter(
    (qstn) => (qstn.cid != undefined ? true : false)
  );

  let unansweredQuestion = contarctQuestions.find((qstn) =>
    qstn.ans == undefined ? true : false
  );

  console.log(unansweredQuestion);

  const feedbackQstn = () => {
    let lastQuestion = context.questions[context.questions.length - 1];

    if (!lastQuestion.options.length) {
      return lastQuestion;
    }

    return null;
  };

  const uploadingFeedback = () => {
    let fbQstn: IQuestionModel = feedbackQstn();

    if (fbQstn && context.updateQuestionWithAnswer) {
      context.setIsUplodaing(true);

      context.updateQuestionWithAnswer({
        ...fbQstn,
        ans: feedbackValue,
      });

      setTimeout(() => {
        context.setIsUplodaing(false);
        onOpen();
      }, 750);
    }
  };

  return (
    <>
      <Header />
      <Slider {...settings} ref={swiperRef}>
        {context.questions.slice(0, 3).map((el) => (
          <SwiperSlide pos={pos} key={el.que}>
            <>
              <QuestionOptionBlock
                {...el}
                index={pos}
                onOptionSelected={(indx, que) => {
                  // slideNext();
                }}
              />
              {el.ans !== undefined ? <Footer onClick={slideNext} /> : ""}
            </>
          </SwiperSlide>
        ))}

        {contarctQuestions.length ? (
          <SwiperSlide pos={pos} key="contact-question">
            <>
              <Text
                mb={4}
                textAlign="center"
                lineHeight="1.5"
                color="primary.500"
                fontWeight="bold"
              >
                Below are your Unpaid Persistency book Policies - <br />
                Kindly Update the Status
              </Text>
              {contarctQuestions.map((qstn, qstnIndex) => (
                <>
                  <Box
                    key={qstn.que}
                    style={{ scrollMarginTop: "4rem" }}
                    id={`qstn${qstnIndex}`}
                  >
                    <QuestionOptionBlock
                      {...qstn}
                      index={pos}
                      onOptionSelected={(indx, que) => {
                        window.location.href = `#qstn${qstnIndex + 1}`;
                      }}
                    />
                  </Box>
                  <Divider
                    mt={4}
                    mb={6}
                    variant="dashed"
                    colorScheme="gray"
                    color="gray.400"
                  />
                </>
              ))}

              {feedbackQstn() ? (
                <Feedback
                  feedbackValue={feedbackValue}
                  setfeedbackValue={setfeedbackValue}
                  question={feedbackQstn()}
                />
              ) : null}

              {unansweredQuestion ? null : (
                <Footer
                  onClick={uploadingFeedback}
                  label="Submit"
                  isSubmitting={context.isUplodaing}
                />
              )}
            </>
          </SwiperSlide>
        ) : null}
      </Slider>

      <ThankYouModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default QuizScreen;

interface ISwiperSlide {
  pos: number;
  showNextButton?: boolean;
  onNextButton?: () => void;
}

const SwiperSlide: FC<ISwiperSlide> = ({ pos, children }) => {
  return (
    <Wrapper p={4} position="relative" pb="80px">
      <Text color="gray.400" fontSize="xs" mb={2} mt={4} textAlign="center">
        {pos + 1} of 4
      </Text>
      {children}
    </Wrapper>
  );
};

interface IFeedback {
  feedbackValue: string;
  setfeedbackValue: (feedbackValue: string) => void;
  question: IQuestionModel;
}

const Feedback = ({ feedbackValue, setfeedbackValue, question }: IFeedback) => {
  useEffect(() => {
    if (feedbackValue == "" && typeof question.ans === "string") {
      setfeedbackValue(question.ans);
    }
  }, []);

  let handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    setfeedbackValue(inputValue);
  };

  return (
    <>
      <Text fontSize="sm" fontWeight="semibold" textAlign="center" mb={4}>
        {question.que}
      </Text>

      <Textarea
        value={feedbackValue}
        onChange={handleInputChange}
        placeholder=" Feedback/Suggestion if Any (Not Mandatory)"
        rows={5}
      />
    </>
  );
};
